import { tryExceptAwait } from '../../../utils'
import orderEndpoints from '../../../api/orders'

const state = {
  fabrics: [],
  orderId: undefined
}

// getters
const getters = {
  getFabrics(state) {
    return state.fabrics
  }
}

// mutations
const mutations = {
  addFabric(state, fabric) {
    state.fabrics = [...state.fabrics, fabric]
  },
  deleteFabric(state, fabric) {
    state.fabrics = state.fabrics.filter(
      (selectedFabric) => selectedFabric.fabricUuid !== fabric.fabricUuid
    )
  },
  clearFabrics(state) {
    state.fabrics = []
  },
  setOrderId(state, orderId) {
    state.orderId = orderId
  }
}

// actions
const actions = {
  addFabric({ commit }, { fabric }) {
    commit('addFabric', fabric)
  },
  deleteFabric({ commit }, { fabric }) {
    commit('deleteFabric', fabric)
  },
  async sendOrder({ dispatch }, { orderForm, samples }) {
    const orderId = await dispatch('createOrder', { form: orderForm })
    if (!orderId) {
      console.error('Error on create order')
      return
    }
    const urlParams = { orderId }
    return (
      (await dispatch('putSamples', { urlParams, form: samples })) &&
      (await dispatch('confirmOrder', {
        urlParams,
        form: { with_transport_cost: false }
      }))
    )
  },
  async createOrder({ state, commit }, { form }) {
    if (state.orderId) {
      return state.orderId
    }
    const response = await tryExceptAwait(orderEndpoints.addOrder, [form])
    if (response.status === 201 && response.data) {
      commit('setOrderId', response.data.id)
      return response.data.id
    }
    return undefined
  },
  async putSamples(_, { urlParams, form }) {
    const response = await tryExceptAwait(orderEndpoints.addOrderSamples, [
      urlParams,
      form
    ])
    if (response.status > 400) {
      console.error('Samples append error')
      return undefined
    } else {
      return response.data
    }
  },
  async confirmOrder({ commit }, { urlParams, form }) {
    const response = await tryExceptAwait(orderEndpoints.confirmOrder, [urlParams, form])
    if (response.status > 400) {
      console.error('Order confirmation error')
      return undefined
    } else {
      commit('setOrderId', undefined)
      return response.data
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
