import { apiClient } from './index'

const getPath = ({ orderId }) => {
  return orderId ? `orders/${orderId}/` : 'orders/'
}

const getSamplesPath = (urlParams) => {
  return `${getPath(urlParams)}articles/samples/`
}

const getConfirmPath = (urlParams) => {
  return `${getPath(urlParams)}confirm/`
}

export default {
  async addOrder(data) {
    return await apiClient.post(getPath({}), data)
  },
  async confirmOrder(urlParams, data) {
    return await apiClient.post(getConfirmPath(urlParams), data)
  },
  async addOrderSamples(urlParams, data) {
    return await apiClient.post(getSamplesPath(urlParams), data)
  }
}
