import { apiClient } from './index'

const basePath = `vadain-fabrics/`

const getPath = ({ fabricUuid }) => {
  return `${basePath}${fabricUuid}/`
}

export default {
  async getFabricList(queryParams) {
    return await apiClient.get(basePath, { params: queryParams })
  },
  async getFabricDetails(urlParams, queryParams) {
    return await apiClient.get(getPath(urlParams), { params: queryParams })
  }
}
