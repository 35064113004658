import {
  tryExceptAwait,
  mapListFromApi,
  mapObjectFromApi,
  setFabricImage
} from '../../../utils'
import fabricsEndpoints from '../../../api/fabrics'
import filters from './filters'

// initial state
const state = {
  fabricList: [],
  fabricDetails: {},
  count: null
}

// getters
const getters = {
  getFabricList(state) {
    return state.fabricList
  },
  getFabricDetails(state) {
    return state.fabricDetails
  },
  getCount(state) {
    return state.count
  }
}

// mutations
const mutations = {
  setFabricList(state, fabrics) {
    state.fabricList = mapListFromApi(fabrics)
  },
  setFabricDetails(state, data) {
    const fabric = mapObjectFromApi(data)
    state.fabricDetails = {
      ...state.fabricDetails,
      [fabric.fabricUuid]: fabric
    }
  },
  setCount(state, count) {
    state.count = count
  }
}

// actions
const actions = {
  async loadFabricList({ commit }, { queryParams }) {
    const response = await tryExceptAwait(fabricsEndpoints.getFabricList, [queryParams])
    if (response.data) {
      // set fabric images
      for (const fabric of response.data.results || []) {
        await setFabricImage(fabric)
      }
      commit('setFabricList', response.data.results)
      commit('setCount', response.data.count)
    }
  },
  async loadFabricDetails({ commit, state }, { urlParams, queryParams }) {
    if (urlParams.fabricUuid in state.fabricDetails) {
      // do not do anything if fabric details already present in state
      return
    }
    const response = await tryExceptAwait(fabricsEndpoints.getFabricDetails, [
      urlParams,
      queryParams
    ])
    if (response.data) {
      // set fabric images
      await setFabricImage(response.data)
      commit('setFabricDetails', response.data)
    }
  }
}

export default {
  namespaced: true,
  modules: {
    filters
  },
  state,
  getters,
  mutations,
  actions
}
