import axios from 'axios'
import { setupCache } from 'axios-cache-interceptor'
// get the store from either the standalone version or the store
// of the application vadain-sample-service is being used in
import store from '@/store'
import { deepCopy, getCamelToKebabDeepMap } from '../utils'
import { getSiteSettings } from '../settings'

const debug = process.env.NODE_ENV !== 'production'
const siteSettings = getSiteSettings()
const apiUrl = process.env.VUE_APP_SS_API_HOST || siteSettings.apiHost
const storeLocatorApiUrl =
  process.env.VUE_APP_STORE_LOCATOR_API_HOST || siteSettings.storeLocatorApiHost

export const getApiClient = (apiUrl, headers = { 'accept-language': 'nl' }) => {
  const apiClient = axios.create({
    baseURL: apiUrl,
    headers
  })

  apiClient.interceptors.response.use(
    function (response) {
      if (debug) {
        console.log('Response:', response)
      }
      return response
    },
    function (error) {
      if (error.response && error.response.data) {
        return Promise.reject(error.response.data)
      }
      return Promise.reject(error.message)
    }
  )

  apiClient.interceptors.request.use(function (config) {
    if (config.data) {
      config.data = getCamelToKebabDeepMap(deepCopy(config.data))
    }
    if (debug) {
      console.log('Request:', config)
    }
    config.headers['accept-language'] = store.getters['user/getSystemLanguage'] || 'en'
    return config
  })

  return apiClient
}

export const apiClient = getApiClient(apiUrl, { 'accept-language': 'nl' })
export const cachedAxios = axios.defaults.cache
  ? axios
  : setupCache(axios, { cacheTakeover: false })
export const storeLocatorApiClient = getApiClient(storeLocatorApiUrl)
