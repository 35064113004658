export const APP_SITE_SETTINGS = {
  // local urls
  localhost: {
    apiHost: '/ss-api',
    locale: 'nl',
    storeLocatorApiHost: '/store-locator-api',
    mapBoxAccessToken:
      'pk.eyJ1IjoieWV2aGVuaWlkZWh0aWFycHJvYmVnaW4iLCJhIjoiY2tiOGF1ZnYwMDJlcTJwbzQxNGh4bzI0NyJ9._FWpQocOI4wRJPSGro1yPA'
  },
  '127.0.0.1': {
    apiHost: '/ss-api',
    locale: 'nl',
    storeLocatorApiHost: '/store-locator-api',
    mapBoxAccessToken:
      'pk.eyJ1IjoieWV2aGVuaWlkZWh0aWFycHJvYmVnaW4iLCJhIjoiY2tiOGF1ZnYwMDJlcTJwbzQxNGh4bzI0NyJ9._FWpQocOI4wRJPSGro1yPA'
  },
  '0.0.0.0': {
    apiHost: '/ss-api',
    locale: 'nl',
    storeLocatorApiHost: '/store-locator-api',
    mapBoxAccessToken:
      'pk.eyJ1IjoieWV2aGVuaWlkZWh0aWFycHJvYmVnaW4iLCJhIjoiY2tiOGF1ZnYwMDJlcTJwbzQxNGh4bzI0NyJ9._FWpQocOI4wRJPSGro1yPA'
  },
  // Dutch sample service urls
  'sampleservice.awsmonkey.nl': {
    apiHost: 'https://gw-sampleservice.awsmonkey.nl',
    locale: 'nl',
    storeLocatorApiHost: 'https://storelocator.vadain.nl',
    mapBoxAccessToken:
      'pk.eyJ1IjoieWV2aGVuaWlkZWh0aWFycHJvYmVnaW4iLCJhIjoiY2tiOGF1ZnYwMDJlcTJwbzQxNGh4bzI0NyJ9._FWpQocOI4wRJPSGro1yPA'
  },
  'sampleservice.acc-awsmonkey.nl': {
    apiHost: 'https://gw-sampleservice.acc-awsmonkey.nl',
    locale: 'nl',
    storeLocatorApiHost: 'https://storelocator.vadain.nl',
    mapBoxAccessToken:
      'pk.eyJ1IjoieWV2aGVuaWlkZWh0aWFycHJvYmVnaW4iLCJhIjoiY2tiOGF1ZnYwMDJlcTJwbzQxNGh4bzI0NyJ9._FWpQocOI4wRJPSGro1yPA',
    sentryDsn: 'https://1ae0d02ba6084d25953a3fced5598c32@o255934.ingest.sentry.io/5501185'
  },
  'sampleservice.vadain.nl': {
    googleAnalyticsId: 'G-PRP9LL4PRM',
    apiHost: 'https://gw-sampleservice.prod-awsmonkey.nl',
    locale: 'nl',
    storeLocatorApiHost: 'https://storelocator.vadain.nl',
    mapBoxAccessToken:
      'pk.eyJ1IjoieWV2aGVuaWlkZWh0aWFycHJvYmVnaW4iLCJhIjoiY2tiOGF1ZnYwMDJlcTJwbzQxNGh4bzI0NyJ9._FWpQocOI4wRJPSGro1yPA',
    sentryDsn: 'https://d2385f5cf4a445a19bfe454030a8ad19@o255934.ingest.sentry.io/5501190'
  },
  // Polish sample service urls
  'sampleservice-pl.awsmonkey.nl': {
    apiHost: 'https://gw-sampleservice-pl.awsmonkey.nl',
    locale: 'pl',
    storeLocatorApiHost: 'https://storelocator.vadain.nl',
    mapBoxAccessToken:
      'pk.eyJ1IjoieWV2aGVuaWlkZWh0aWFycHJvYmVnaW4iLCJhIjoiY2tiOGF1ZnYwMDJlcTJwbzQxNGh4bzI0NyJ9._FWpQocOI4wRJPSGro1yPA'
  },
  'sampleservice-pl.acc-awsmonkey.nl': {
    apiHost: 'https://gw-sampleservice-pl.acc-awsmonkey.nl',
    locale: 'pl',
    storeLocatorApiHost: 'https://storelocator.vadain.nl',
    mapBoxAccessToken:
      'pk.eyJ1IjoieWV2aGVuaWlkZWh0aWFycHJvYmVnaW4iLCJhIjoiY2tiOGF1ZnYwMDJlcTJwbzQxNGh4bzI0NyJ9._FWpQocOI4wRJPSGro1yPA',
    sentryDsn: 'https://1ae0d02ba6084d25953a3fced5598c32@o255934.ingest.sentry.io/5501185'
  },
  'sampleservice-pl.prod-awsmonkey.nl': {
    apiHost: 'https://gw-sampleservice-pl.prod-awsmonkey.nl',
    locale: 'pl',
    storeLocatorApiHost: 'https://storelocator.vadain.nl',
    mapBoxAccessToken:
      'pk.eyJ1IjoieWV2aGVuaWlkZWh0aWFycHJvYmVnaW4iLCJhIjoiY2tiOGF1ZnYwMDJlcTJwbzQxNGh4bzI0NyJ9._FWpQocOI4wRJPSGro1yPA',
    sentryDsn: 'https://d2385f5cf4a445a19bfe454030a8ad19@o255934.ingest.sentry.io/5501190'
  },
  'sampleservice.vadain.pl': {
    apiHost: 'https://gw-sampleservice-pl.prod-awsmonkey.nl',
    locale: 'pl',
    storeLocatorApiHost: 'https://storelocator.vadain.nl',
    mapBoxAccessToken:
      'pk.eyJ1IjoieWV2aGVuaWlkZWh0aWFycHJvYmVnaW4iLCJhIjoiY2tiOGF1ZnYwMDJlcTJwbzQxNGh4bzI0NyJ9._FWpQocOI4wRJPSGro1yPA',
    sentryDsn: 'https://d2385f5cf4a445a19bfe454030a8ad19@o255934.ingest.sentry.io/5501190'
  }
}

export const getSiteSettings = () => {
  /*
   * Get the site settings object for the current site / hostname.
   */
  const locationHostname = window.location.hostname
  // get the settings object with the hostname that's the closest
  // match to the current hostname
  if (APP_SITE_SETTINGS[locationHostname]) {
    return APP_SITE_SETTINGS[locationHostname]
  } else if (process.env.NODE_ENV === 'development') {
    return APP_SITE_SETTINGS['localhost']
  }
  let matchLength = 0
  let currentSiteSettings = {}
  Object.keys(APP_SITE_SETTINGS).forEach((hostname) => {
    if (hostname.length > matchLength && locationHostname.includes(hostname)) {
      matchLength = locationHostname.length
      currentSiteSettings = APP_SITE_SETTINGS[hostname]
    }
  })
  return currentSiteSettings
}
